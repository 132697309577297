import { useState, useEffect } from "react";
import axios from "axios";

import * as Yup from "yup";
import { TheFirstStepSchema } from "app/const/yup";
import { FastField, Form, Formik } from "formik";

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import InputField from "app/components/customField/inputField";
import SelectField from "app/components/customField/selectField";
import DatePickerField from "app/components/customField/datePickerField";
import { DEFALT_OPTIONS } from "app/components/customField/selectField/options";

import "./styles.scss";
import { isEmpty } from "lodash";
// import { addCourseToCart } from "core/redux/actions/cartAction";
// import { useDispatch } from "react-redux";

const ButtonSubmit = styled(Button)`
  color: #fff;
  font-size: 17px;
  background-image: linear-gradient(rgb(255, 0, 191), #8420be);
  :hover {
    color: #fff;
    background-image: linear-gradient(#8420be, rgb(255, 0, 191));
  }
`;

function RegisterForm(props) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const { title, ticket } = props;
  // const dispatch = useDispatch();
  console.log("props", props);
  const storedParams = localStorage.getItem("utm");
  const parsedParams = JSON.parse(storedParams);
  const handleRegister = async (data) => {
    props?.setOpenModal(false);
    let price = 0;
    let course = props?.course;
    let ticket = "";

    if (props.course === "mountain") {
      let product = {
        course: props.course,
        mountain: props.mountain,
        user: data,
        count: 1,
        price: price,
        checkout: false,
      };
      console.log('true', product);
      setLoading(true);
      handleSendData(product);

    } else {
      if (props.course.type === "courseOffline" && props.course.course === "tr") {
        price =
          data.loaive === "Vip"
            ? 3600000
            : data.loaive === "Platinum"
              ? 3000000
              : 199000;
        ticket = data.loaive;
      } else {
        price = props.course.price;
        ticket = "";
      }
      let product = {
        type: course.type,
        course_name: course.name,
        course: course.course,
        img: course.img,
        ticket: ticket,
        user: data,
        count: 1,
        price: price,
        checkout: false,
      };
      // dispatch(addCourseToCart(product));
      setLoading(true);
      handleSendData(product);
    }

  };

  const handleSendData = async (data) => {
    props?.setData(data);
    console.log('sendssssssssssssss', data)
    if (data?.course === "tr") {
      data["tags"] = ["Vas-TheFirstStep"];
      console.log('true tr ')
    } else if (data?.course === "mountain") {
      data["tags"] = ["Mountain-FSP"];
    } else {
      data["tags"] = ["Vas-RegisterCourseOffline"];
    }

    data["import_by_tag_name"] = true;

    const finalData = {
      ...data,
      ...data.user,
      ...parsedParams,
    };
    console.log("finalData", finalData);
    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data: finalData,
    })
      .then((response) => {
        setLoading("response", response);
        props?.setStep(1);
        props?.setOpenModal(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const initialValues = {
    first_name: "",
    email: "",
    phone: "",
    dob: 33756000,
    loaive:
      ticket === "VIP" ? "Vip" : ticket === "PLATINUM" ? "Platinum" : "General",
    address: "",
    tunghoc: "",
    songuoidicung: 0,
  };

  // const initialValues = {
  //   dob: 33756000,
  //   email: "ttkien94@gmail.com",
  //   gender: "Nam",
  //   first_name: "trinh trung kien",
  //   loaive:
  //     ticket === "VIP" ? "Vip" : ticket === "PLATINUM" ? "Platinum" : "General",
  //   phone: "0383204367",
  //   songuoidicung: 0,
  //   address: "",
  // };
  const validationSchema = Yup.object().shape(TheFirstStepSchema);

  const RenderUI = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="registerForm ">
            <div className="d-flex justify-content-center frame-bg ">
              <h3 className=" pt-2  header-title ">{title}</h3>
            </div>
            <div className="d-flex justify-content-center  ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleRegister}
                enableReinitialize
              >
                {(formikProps) => {
                  const { values, errors, touched } = formikProps;

                  console.log({ values, errors, touched });

                  return (
                    <Form
                      className=" mt-4 py-3 form-body row"
                      style={{
                        maxWidth: "700px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      <div className="col-12 col-md-6">
                        <FastField
                          name="first_name"
                          component={InputField}
                          label="Tên đầy đủ"
                          placeholder="Nhập tên"
                          className="w-100 mb-4"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <FastField
                          name="email"
                          component={InputField}
                          label="Email"
                          placeholder="Nhập email"
                          className="w-100 mb-4"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <FastField
                          name="phone"
                          component={InputField}
                          label="Số điện thoại"
                          placeholder="Nhập số điện thoại"
                          className="w-100 mb-4"
                          autocomplete
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FastField
                          name="tunghoc"
                          component={SelectField}
                          label="Đã từng học các khóa The Origins"
                          className="w-100 mb-4"
                          options={DEFALT_OPTIONS.tunghoc}
                        />
                      </div>
                      {props?.course === "KeyToSuccess" ? (
                        <></>
                      ) : (
                        <>
                          <div className="col-12 col-md-6 mb-2">
                            <FastField
                              name="dob"
                              component={DatePickerField}
                              label="Ngày sinh"
                              placeholder="Nhập ngày sinh"
                              className="w-100 mb-2 mb-md-4"
                            />
                            {errors.dob && (
                              <p className=" text-danger MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root mt-n3">
                                {errors.dob}
                              </p>
                            )}
                          </div>
                          {props.course?.course === "tr" && (
                            <div className="col-12 col-md-6">
                              <FastField
                                name="loaive"
                                component={SelectField}
                                label="Loại vé"
                                className="w-100 mb-4"
                                options={DEFALT_OPTIONS.loaive}
                              />
                            </div>
                          )}

                          <div className="col-12 col-md-6">
                            <FastField
                              name="address"
                              component={InputField}
                              label="Địa chỉ"
                              placeholder="Nhập địa chỉ"
                              className="w-100 mb-4"
                            />
                          </div>
                          {props.course?.course === "tr" && (
                            <div className="col-12 col-md-6">
                              <FastField
                                name="songuoidicung"
                                component={InputField}
                                label="Số người đi cùng"
                                placeholder="Số người đi cùng"
                                className="w-100 mb-4"
                                autocomplete
                              />
                            </div>
                          )}
                        </>
                      )}
                      <div className="col-12 justify-content-center d-flex">
                        <ButtonSubmit
                          type="submit"
                          className=""
                          disabled={
                            isEmpty(errors) && values.email ? false : true
                          }
                        >
                          Đăng ký
                          {loading && <div className="loader ml-1"></div>}
                        </ButtonSubmit>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        );
      case 1:
        return (
          <>
            <div className="OTPContainer">
              <div className="formContainerOTP">
                <div className="formHeading">
                  <h5>Soul Retreats đã gửi một Email xác thực đến cho bạn</h5>
                </div>

                <p className="descText px-4">
                  Vui lòng kiểm tra hộp thư đến, có thể Email nằm trong mục spam
                  của bạn.
                </p>
              </div>
            </div>
          </>
        );

      default:
        throw new Error("Invalid Screen!!!");
    }
  };
  useEffect(() => {
    console.log("final :", props.course);
  }, []);
  return RenderUI(step);
}

export default RegisterForm;
