import React, { useEffect, useState, useRef } from "react";
import PyramidDisplay from "app/modules/ClientModules/courses/courseOnline/components/pyramid";
import { isMobile } from "react-device-detect";
import "./styles.scss";
import { pyramidItemsLayout, colorsData, stationItemsLayout, videoInfoAndQuestion } from "./const";
import { Box, Modal, IconButton } from "@mui/material";
import RegisterForm from "share/registerForm";
import CloseIcon from "@mui/icons-material/Close";
import { arrayMountain } from "../../../ClientModules/courses/courseOffline/const";
import FspBg from "assets/images/course/course-online/fspBg.jpg";
import FspTitle from "assets/images/course/course-online/FSPtitle.png";
import FSPTitle from "assets/images/course/course-online/chinhphucfansipan.png";
import FSPVas from "assets/images/course/course-online/VasFSP.png";
import FSPElearning from "assets/images/course/course-online/titleFSP.png";
import Player from "@vimeo/player";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import _, { isEmpty, isNull } from "lodash";
import PopupQuestion from "share/popupQuestion";
import { updateUserAction } from "core/redux/actions/authAction";
import { getTopTenUsers } from "core/redux/actions/userAction";
function Fansipan() {
    const [data, setData] = useState(pyramidItemsLayout);
    const [indexSelected, setIndexSelected] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openQuestion, setOpenQuestion] = useState(false);
    const [openStationQuestion, setOpenStationQuestion] = useState(false);
    const [stationIndex, setStationIndex] = useState(0);
    const [step, setStep] = useState(0);
    const [dataReg, setDataReg] = useState({});
    const storedParams = localStorage.getItem("utm");
    const parsedParams = JSON.parse(storedParams);
    const [selectedPack, setSelectedPack] = useState(pyramidItemsLayout[3][0]);
    const [stationData, setStationData] = useState(stationItemsLayout);
    const [popupVisible, setPopupVisible] = useState([false, false, false]);
    const videoContainerRef = useRef(null);
    const videoRef = useRef(null);
    const [videoWatched, setVideoWatched] = useState(false);
    const [player, setPlayer] = useState(null);
    const [userData, setUserData] = useState({});
    const token = localStorage.getItem("accessToken");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { userInfo } = useSelector((state) => state.auth);
    const topTenUsers = useSelector(state => state.user.topTenUsers);

    useEffect(() => {
        if (isEmpty(userInfo)) {
            setUserData(null);
        } else {
            setUserData(userInfo);
            updateColorsPyramid(userInfo?.mountainStudied?.fansipan);
            updateStationPyramid(userInfo?.mountainOther?.fansipanStation);
        }

        if (userInfo?.mountainStudied?.fansipan === 5 && userInfo?.mountainOther?.fansipanStation === 0 && userInfo?.mountainRegister?.fansipan) {
            setStationIndex(10);
            setOpenStationQuestion(true);
        }
        if (userInfo?.mountainStudied?.fansipan === 8 && userInfo?.mountainOther?.fansipanStation === 1 && userInfo?.mountainRegister?.fansipan) {
            setStationIndex(11);
            setOpenStationQuestion(true);
        }
        if (userInfo?.mountainStudied?.fansipan === 11 && userInfo?.mountainOther?.fansipanStation === 2 && userInfo?.mountainRegister?.fansipan) {
            setStationIndex(12);
            setOpenStationQuestion(true);
        }
    }, [userInfo]);

    useEffect(() => {
        if (videoRef.current) {
            const newPlayer = new Player(videoRef.current, {
                url: videoInfoAndQuestion[indexSelected]?.url,
            });

            newPlayer.on("timeupdate", ({ percent }) => {
                if (percent >= 0.9) {
                    setVideoWatched(true);
                }
            });

            setPlayer(newPlayer);

            return () => newPlayer.destroy();
        }
    }, [indexSelected]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getTopTenUsers());
    }, []);

    const handleNextPackClick = () => {
        const currentLabel = selectedPack.label;
        let nextPack = null;

        pyramidItemsLayout.some(row => {
            const foundPack = row.find(pack => pack.label === currentLabel + 1);
            if (foundPack) {
                nextPack = foundPack;
                return true;
            }
            return false;
        });

        if (nextPack) {
            handlePackClick(nextPack);
        } else {
            console.log("No more packs available.");
        }
    };

    const handlePackClick = (pack) => {
        setVideoWatched(false);
        setSelectedPack(pack);
        setIndexSelected(pack.label - 1);
        switch (pack.label) {
            case "2":
            case "3":
            case "4":
                console.log("----floor1", pack);
                break;
            case "5":
            case "6":
            case "7":
                console.log("----floor2", pack);
                break;
            case "8":
            case "9":
            case "10":
                console.log("----floor3", pack);
                break;
            default:
                console.log("free video", pack);
        }
        if (videoRef.current) {
            videoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const updateColorsPyramid = (fansipanLevel) => {
        pyramidItemsLayout.forEach(row => {
            row.forEach(item => {
                if (item.label <= fansipanLevel - 1) {
                    item.color = "#0000FF";
                }
            });
        });
        setData([...pyramidItemsLayout]);
    };
    const updateStationPyramid = (num) => {
        stationItemsLayout.forEach((item, index) => {
            if (index < num) {
                item.color = "#0000FF";
            }
        });
        setStationData([...stationItemsLayout]);
    };
    const renderSwitch = () => {
        switch (step) {
            case 0:
                return (
                    <RegisterForm
                        title={"Thông tin đăng ký !"}
                        course={arrayMountain[0].mountainTag}
                        mountain={"FSP"}
                        setStep={(i) => {
                            setStep(i);
                        }}
                        setOpenModal={(i) => {
                            setOpenModal(i);
                        }}
                        setDataReg={(i) => {
                            setDataReg(i);
                        }}
                        parsedParams={parsedParams}
                    />
                );
            case 1:
                return (
                    <div className="popup">
                        <div className="popup-background"></div>
                        <div className="overlay" />
                        <div className="col-12 position-relative">
                            <div className="px-5 py-5">
                                <div className="row">
                                    <h2>CHÚC MỪNG BẠN </h2>
                                    <h4 style={{ textAlign: "center" }}>
                                        ĐÃ ĐĂNG KÝ KHÓA HỌC ONLINE
                                    </h4>
                                    <h4 style={{ textAlign: "center" }}>
                                        E-LEARNING SKILLSET
                                    </h4>
                                </div>
                                <div className="row mt-4 text-center">
                                    <p> Hành trình phát triển kỹ năng của bạn chính thức bắt đầu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <></>;
        }
    };
    const renderPopUp = () => {
        const style = {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            position: "absolute",
            display: "block",
            width: isMobile ? "90%" : "50%",
            maxHeight: "80%",
            overflow: isMobile ? "scroll" : "auto",
            "&:focus-visible": {
                outline: "0px solid #1976d2",
                boxShadow: 12,
            },
        };
        return (
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setStep(0);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={
                    {
                        // borderRadius: "40px",
                    }
                }
            >
                <Box sx={style}>
                    <IconButton
                        onClick={() => {
                            setOpenModal(false);
                            setStep(0);
                        }}
                        sx={{ position: "absolute", top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {renderSwitch()}
                </Box>
            </Modal>
        );
    };
    const renderVideoContainer = () => {
        console.log("index", indexSelected, "studied : ", userData?.mountainStudied?.fansipan)
        console.log((userInfo?.mountainOther?.fansipanMarketing && (indexSelected + 1 < userInfo?.mountainStudied?.fansipan)))
        console.log("default", (userData && (userData?.mountainRegister?.fansipan && indexSelected < userData?.mountainStudied?.fansipan) || (indexSelected) === 0))
        return (
            <div className="row d-flex justify-content-center text-center py-10 position-relative" ref={videoContainerRef}>
                {((userData && (userData?.mountainRegister?.fansipan && indexSelected < userData?.mountainStudied?.fansipan)) || (indexSelected) === 0 || (userInfo?.mountainOther?.fansipanMarketing && (indexSelected < userInfo?.mountainStudied?.fansipan) && indexSelected + 1 < 5)) ? (
                    <>
                        <div className="video-study-content">
                            <h1 className="centered-text pt-10" style={{ color: 'white', fontSize: '3rem' }}>
                                {videoInfoAndQuestion[indexSelected]?.title || "Video Title"}
                            </h1>
                            <div className="video-study-container">
                                <div ref={videoRef}></div>
                            </div>
                        </div>

                        {videoWatched && (indexSelected + 1 === userData?.mountainStudied?.fansipan) ? (
                            <button
                                onClick={() => setOpenQuestion(true)}
                                style={{
                                    color: 'white',
                                    padding: '10px 20px',
                                    backgroundColor: '#007bff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    width: '200px',
                                }}
                            >
                                Làm Bài Tập Để Nhận Điểm Thưởng
                            </button>
                        ) : videoWatched ? (
                            <button
                                onClick={() => handleNextPackClick()}
                                style={{
                                    color: 'white',
                                    padding: '10px 20px',
                                    backgroundColor: '#007bff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    width: '200px',
                                }}
                            >
                                Bài tiếp theo
                            </button>
                        ) : (<> </>)
                        }
                        <h3 className="pt-10" style={{ color: 'white' }}>
                            Hãy xem hết video để được trả lời câu hỏi tích điểm
                        </h3>

                        {renderPopUp()}
                    </>
                ) : (
                    <>
                        <div className="video-study-content">
                            <h1 className="centered-text pt-10" style={{ color: 'white', fontSize: '3rem' }}>
                                {videoInfoAndQuestion[indexSelected]?.title || "Video Title"}
                            </h1>
                            <div className="video-study-container">
                                <div ref={videoRef}></div>
                            </div>
                        </div>

                        <div className="blurred-content-overlay">
                            {isNull(userData) || isEmpty(userData) ? (
                                <Link to="/dang-nhap" state={{ from: location }} className="login-button">
                                    Đăng Nhập Ngay
                                </Link>
                            ) : (userData.mountainRegister?.fansipan || (userData.mountainOther?.fansipanMarketing && indexSelected < 4)) ? (
                                <>
                                    <h1>
                                        Hãy Hoàn Thành Trả Lời Câu Hỏi Và Video Theo Thứ Tự <br />
                                        <div
                                            style={{ color: 'red', fontSize: '2rem', cursor: 'pointer' }}
                                            onClick={() => setIndexSelected(userData?.mountainStudied?.fansipan - 1)}
                                        >
                                            (Bấm vào để quay lại bài {userData?.mountainStudied?.fansipan})
                                        </div>
                                    </h1>
                                </>
                            ) : (
                                <h1>Hãy Liên Hệ Với Chúng Tôi Để Được Tư Vấn Hoặc Mua Khóa Học</h1>
                            )}
                        </div>
                    </>
                )}
            </div>
        );

    };
    const renderMountainTitle = () => {
        return (
            <div>
                <div className="row screen-split-container pb-10">

                    <div className="mountain-title">
                        <img className="coach-vas" src={FSPVas} alt="Coach Vasanth Gopalan" />
                        <img className="fsp" src={FSPTitle} alt="Fansipang" />
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 screen-section screen-left ">
                            <div className="promo-card">
                                <img style={{ width: '70%', display: 'block', margin: '0 auto' }} src={FSPElearning} alt="Coach Vasanth Gopalan" />
                                <div className="listFSP pt-10">
                                    <ul>
                                        <li>3 TRẠM NGHỈ | 10 VIDEO | 70 PHÚT</li>
                                        <li>Học cùng chuyên gia Huấn Luyện quốc tế Vasanth Gopalan</li>
                                        <li>Tích lũy điểm qua các video để được các ưu đãi khóa học khác</li>
                                        <li>Cạnh tranh các học viên khác để có cơ hội leo Fansipan miễn phí cùng Coach Vasanth Gopalan</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 screen-section screen-left ">
                            <div className="promo-card h-100">

                                <h2 className="text-center">BẢNG XẾP HẠNG</h2>
                                {/* <p className="text-center">Ngày chốt bốc thăm 1 người trong top 10: 31/12/2024</p> */}
                                <div className="listFSP">
                                    <ol>
                                        {topTenUsers.map((user) => (
                                            <li key={user._id} className="user-entry">
                                                <span className="user-name">{user.name}</span>
                                                <span className="user-power" style={{ color: 'red', fontWeight: 'bolder' }}> - Điểm tích lũy: {user.power}</span>
                                                <span className="user-phone"> - SĐT: {formatPhone(user.phone)}</span>
                                            </li>
                                        ))}
                                    </ol>
                                    {userInfo && userInfo.name && (
                                        <h4>
                                            {userInfo.name}
                                            <span style={{ color: 'red', fontWeight: 'bolder', fontSize: '1.5rem' }}>
                                                - Điểm tích lũy: {userInfo.power}
                                            </span>
                                            - SĐT: {formatPhone(userInfo.phone)}
                                        </h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const formatPhone = (phone) => {
        if (phone && phone.length === 10) {
            return `${phone.slice(0, 2)}xxxxx${phone.slice(7)}`;
        }
        return phone;
    };
    const renderFSPPyramid = () => {
        return (
            <div className="row background pt-20">
                <div className="col">
                    <PyramidDisplay
                        data={data}
                        onPackClick={handlePackClick}
                        stationData={stationData}
                        popupVisible={popupVisible}
                        isScreensplit={false}
                        onStationClick={handleStationClick} // Ensure this is passed correctly
                    />
                </div>
                <div id='groundChange' className="color-box-row py-10" style={{ backgroundColor: '#808080' }}>
                    {colorsData.map((item, index) => (
                        <div className="color-box-wrapper" key={index}>
                            <div className="color-box-item" style={{ backgroundColor: item.color }}>
                            </div>
                            <p className="description">{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>

        )
    };

    const handleQuestionSubmit = (userAnswers) => {
        let correctAnswers;
        let score = 0;
        let tempCount = 0;
        const userResults = Object.values(userAnswers);
        const dataSubmit = {};
        if (stationIndex === 0) {
            correctAnswers = videoInfoAndQuestion[indexSelected].question.map(q => q.result);
        } else {
            correctAnswers = videoInfoAndQuestion[stationIndex].question.map(q => q.result);
        }
        correctAnswers.forEach((answer, index) => {
            if (answer === userResults[index]) {
                if (stationIndex === 11 || stationIndex === 12) {
                    score += 100;
                } else {
                    score += 50;
                }
                tempCount++;
            }
        });
        dataSubmit["power"] = userInfo?.power + score;

        if (stationIndex === 10 || stationIndex === 11 || stationIndex === 12) {
            dataSubmit["mountainOther"] = {
                ...userInfo?.mountainOther,
                fansipanStation: (userInfo?.mountainOther.fansipanStation || 0) + 1
            };
        } else {
            dataSubmit["mountainStudied"] = {
                ...userInfo?.mountainStudied,
                fansipan: (userInfo?.mountainStudied.fansipan || 0) + 1
            };
        }
        console.log("dataSubmit", dataSubmit);
        if (userData) {
            dispatch(updateUserAction(userInfo?._id, dataSubmit));
        }
        alert(`Chúc mừng bạn trả lời đúng ${tempCount} câu và nhận được ${score} năng lượng tích lũy. Bấm Ok để tiếp tục`);
        setOpenQuestion(false);
        setOpenStationQuestion(false);
        setStationIndex(0);
    };
    const renderPopupQuestion = () => {
        const currentVideoData = videoInfoAndQuestion[indexSelected];
        return (
            <PopupQuestion
                currentVideoData={currentVideoData}
                openQuestion={openQuestion}
                setOpenQuestion={setOpenQuestion}
                onSubmit={handleQuestionSubmit}
                closeOnOutsideClick={true}
            />
        );
    };

    const renderStationPopupQuestion = () => {
        const currentVideoData = videoInfoAndQuestion[stationIndex];
        return (
            <PopupQuestion
                currentVideoData={currentVideoData}
                openQuestion={openStationQuestion}
                setOpenQuestion={setOpenStationQuestion}
                onSubmit={handleQuestionSubmit}
                closeOnOutsideClick={false}
            />
        );
    };
    const handleStationClick = (station) => {
        console.log("Station clicked:", station);
        // Add your logic here
    };

    return (
        <div>
            <div className="parallax"
                style={{
                    background: `url(${FspTitle})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                }}>
                {renderMountainTitle()}
                {renderFSPPyramid()}
            </div>
            <div className="parallax" style={{
                background: `url(${FspBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                height: '100%'
            }} >

                {indexSelected === 0 ? (
                    <h1 className="text-center pt-10" style={{ color: 'white' }}>BẠN ĐÃ ĐƯỢC NHẬN BÀI MIỄN PHÍ ĐẦU TIÊN</h1>) : (<></>)}
                {renderVideoContainer()}
                {renderPopupQuestion()}
                {renderStationPopupQuestion()}
            </div>
        </div>
    )
}

export default Fansipan;
