export const CONTENT_MENU = [
  {
    title: "THE ORIGINS",
    url: "/",
    childMenu: [
      {
        title: "about_vas",
        url: "/sr/mr-vas",
      },
      {
        title: "about_gt",
        url: "/sr/ms-la-ha-giang-thanh",
      },
      // {
      //   title: "vision",
      //   url: "/sr/tam-nhin-su-menh-sr",
      // },
      // {
      //   title: "recruitment",
      //   url: "/sr/tuyen-dung",
      // },
      // {
      //   title: "rules",
      //   url: "/sr/noi-quy-quy-dinh",
      // },
    ],
  },
  { title: "tr", url: "/the-restart" },
  { title: "course_OfflineTitle", url: "/chuong-trinh-huan-luyen/offline" },
  // { title: "course_OnlineTitle", url: "/chuong-trinh-huan-luyen/online" },
  {
    title: "course_OnlineTitle",
    url: "/khoa-hoc-online",
    childMenu: [
      {
        title: "Fansipan",
        url: "/khoa-hoc-online/fansipan",
      },
      {
        title: "KinaBalu",
        url: "/khoa-hoc-online/fansipan",
      },
      {
        title: "KiliManjaro",
        url: "/khoa-hoc-online/fansipan",
      },
      {
        title: "Aconcagua",
        url: "/khoa-hoc-online/fansipan",
      },
      // {
      //   title: "vision",
      //   url: "/sr/tam-nhin-su-menh-sr",
      // },
      // {
      //   title: "recruitment",
      //   url: "/sr/tuyen-dung",
      // },
      // {
      //   title: "rules",
      //   url: "/sr/noi-quy-quy-dinh",
      // },
    ],
  },
  // { title: "course", url: "/admin/quan-ly-khoa-hoc" },
  // { title: "student_info", url: "/admin/quan-ly-hoc-vien" },

  // {
  //   title: "training_program",
  //   url: "/chuong-trinh-huan-luyen",
  //   childMenu: [
  //     {
  //       title: "live_course",
  //       url: "/chuong-trinh-huan-luyen/offline",
  //     },
  //     {
  //       title: "online_course",
  //       url: "/chuong-trinh-huan-luyen/online",
  //     },
  //     {
  //       title: "media_course",
  //       url: "/chuong-trinh-huan-luyen/media",
  //     },
  //   ],
  // },
  // {
  //   title: "cooperate",
  //   url: "/",
  //   childMenu: [
  //     {
  //       title: "performance",
  //       url: "/hop-tac/toi-uu-thanh-tich",
  //     },
  //     {
  //       title: "corporation",
  //       url: "/hop-tac/huan-luyen-doanh-nghiep",
  //     },
  //     {
  //       title: "education",
  //       url: "/hop-tac/dao-tao-the-he-tre",
  //     },
  //     {
  //       title: "coaching",
  //       url: "/hop-tac/tu-van-tri-lieu-ca-nhan",
  //     },
  //   ],
  // },
  // {
  //   title: "posts",
  //   url: "/tram-doc-cam",
  // },
  // {
  //   title: "personality_test",
  //   url: "/bai-thu-tinh-cach",
  // },
  // {
  //   title: "shop",
  //   url: "/cua-hang",
  // },
];
